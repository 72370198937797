import {
  Card,
  Page,
  Box,
  Layout,
  PageSection,
  Cell,
  Divider,
  Button,
  Text,
  MarketingLayout,
  Image,
  IconButton,
  Tooltip,
  WixDesignSystemProvider,
  Loader,
  CircularProgressBar,
  EmptyState,
  Badge,
  AnnouncementModalLayout,
  Modal,
  LinearProgressBar,
  Table,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import {
  AIFilled,
  ExternalLinkSmall,
  PremiumFilled,
  Reviews,
} from "@wix/wix-ui-icons-common";
import React from "react";
import AltTextThumbnail from "./assets/alt-text-thumbnail.png";
import TextAiThumbnail from "./assets/text-ai-thumbnail.png";
import "./App.css";
import axios from "axios";
import { ReactTagManager } from "react-gtm-ts";
import SEOChecklist from "./seochecklistthumbnail.jpg";
import Semrush from "./assets/semrush.png";
import SiteChecker from "./assets/sitechecker.jpeg";
import ErrorBoundary from "./Components/ErrorBoundary";
import Pro from "./assets/pro.png";
import * as BC from "@certifiedcode/base-components";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const appId = "a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c";

const baseUrl = "https://api.certifiedco.de/_functions/v1/ai";

const CONTENT = [
  "Everything in Unlimited +",
  "Dedicated onboarding specialist",
  "Optimization sessions with expert",
  "Site down checker & notifier",
  "Priority Support",
  "Setup & optimize for you",
];

const INTERVAL = 4000;

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dashboard, setDashboard] = React.useState(null as any);

  // const [locations, setLocations] = React.useState([] as any[]);

  // const [locationSearchText, setLocationSearchText] = React.useState("");

  // const locationOptions = locations.filter(v => v.location_type === 'Country').map(v => { return { id: v.location_code, value: v.location_name } })

  const [isBrokenLinkScannerOpen, setIsBrokenLinkScannerOpen] =
    React.useState(false);
  const [brokenLinkScannerProgress, setBrokenLinkScannerProgress] =
    React.useState(0);
  const [brokenLinkScannerResult, setBrokenLinkScannerResult] = React.useState(
    null as any
  );
  const [brokenLinkScannerSubscribed, setBrokenLinkScannerSubscribed] =
    React.useState(false);

  const [isUpScannerOpen, setIsUpScannerOpen] = React.useState(false);
  const [upScannerProgress, setUpScannerProgress] = React.useState(0);
  const [upScannerResult, setUpScannerResult] = React.useState(null as any);
  const [upScannerSubscribed, setUpScannerSubscribed] = React.useState(false);
  const [index, setIndex] = React.useState<number>(0);

  React.useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  React.useEffect(() => {
    axios
      .get(
        "https://api.certifiedco.de/_functions/v1/ai/getDashboardByInstance",
        {
          params: {
            instance: new URLSearchParams(window.location.search).get(
              "instance"
            ),
          },
        }
      )
      .then((response) => {
        setDashboard(response.data);
        setIsLoaded(true);
        getBrokenLinksSubscription();
        getUpSubscription();
        setIntercomIdentity();
        // getLocations();
      });
  }, []);

  var isUpgraded = (dashboard as any)?.instance?.instance?.isFree == false;

  const token = new URLSearchParams(window.location.search).get("token");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c&redirectUrl=https://api.certifiedco.de/_functions/v1/ai/auth`;
  }

  function setIntercomIdentity() {
    fetch(
      "https://api.certifiedco.de/_functions/@certifiedcode/base-backend/intercom",
      {
        method: "GET",
        headers: {
          Authorization:
            new URLSearchParams(window.location.search).get("instance") || "",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      });
  }

  function getBrokenLinks() {
    setBrokenLinkScannerProgress(0);
    setBrokenLinkScannerResult(null);

    // add progress by 10 every 1 second until 100
    const interval = setInterval(() => {
      setBrokenLinkScannerProgress((oldProgress) => {
        if (oldProgress > 90) {
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
    fetch(baseUrl + "/site/brokenlink", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBrokenLinkScannerProgress(100);
          clearInterval(interval);
          setBrokenLinkScannerResult(data);
        } else {
          setBrokenLinkScannerProgress(100);
          clearInterval(interval);
          setBrokenLinkScannerResult({
            timestamp: 1703830330773,
            apiVersion: "1.0.7",
            apiStatus: "success",
            apiCode: 200,
            message:
              "Please publish your site & make sure the site is accessible.",
            // meta: {
            //   url: "https://certifiedcode.wixsite.com/test-lib",
            //   proxyCountry: "United States",
            //   followRedirect: true,
            //   redirectedURL: "https://certifiedcode.wixsite.com/test-lib",
            //   test: {
            //     id: "by3lg5mf08fbxsz7xb5me6aq6o3s7vxm",
            //   },
            // },
          });
        }
      });
  }

  function getBrokenLinksSubscription() {
    setIsSaving(true);
    fetch(baseUrl + "/site/getBrokenLinkCron", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBrokenLinkScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  function createBrokenLinksSubscription() {
    setIsSaving(true);
    fetch(
      baseUrl +
        (brokenLinkScannerSubscribed
          ? "/site/deleteBrokenLinkCron"
          : "/site/createBrokenLinkCron"),
      {
        method: "POST",
        headers: {
          Authorization:
            new URLSearchParams(window.location.search).get("instance") || "",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBrokenLinkScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  function getUp() {
    setUpScannerProgress(0);
    setUpScannerResult(null);

    // add progress by 10 every 1 second until 100
    const interval = setInterval(() => {
      setUpScannerProgress((oldProgress) => {
        if (oldProgress > 90) {
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
    fetch(baseUrl + "/status/up", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpScannerProgress(100);
          clearInterval(interval);
          setUpScannerResult(data);
        } else {
          setUpScannerProgress(100);
          clearInterval(interval);
          setUpScannerResult({
            timestamp: 1703830330773,
            apiVersion: "1.0.7",
            apiStatus: "success",
            apiCode: 200,
            message:
              "Please publish your site & make sure the site is accessible.",
            // meta: {
            //   url: "https://certifiedcode.wixsite.com/test-lib",
            //   proxyCountry: "United States",
            //   followRedirect: true,
            //   redirectedURL: "https://certifiedcode.wixsite.com/test-lib",
            //   test: {
            //     id: "by3lg5mf08fbxsz7xb5me6aq6o3s7vxm",
            //   },
            // },
          });
        }
      });
  }

  function getUpSubscription() {
    setIsSaving(true);
    fetch(baseUrl + "/status/getUpCron", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  function createUpSubscription() {
    setIsSaving(true);
    fetch(
      baseUrl +
        (upScannerSubscribed ? "/status/deleteUpCron" : "/status/createUpCron"),
      {
        method: "POST",
        headers: {
          Authorization:
            new URLSearchParams(window.location.search).get("instance") || "",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  // function getLocations() {
  //   fetch(baseUrl + "/locations", {
  //     method: "GET",
  //     headers: {
  //       "Authorization": new URLSearchParams(window.location.search).get('instance') || ""
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       setLocations(data)
  //     })
  // }

  if ((!dashboard || dashboard["success"] === false) && isLoaded) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page sidePadding={0}>
          <Page.Content>
            <Box
              padding="10px"
              align="center"
              verticalAlign="middle"
              height={"90vh"}
              direction="vertical"
            >
              <Cell>
                <CircularProgressBar error value={100} />
              </Cell>
              <EmptyState
                theme={"page-no-border"}
                title="Ops! Something went wrong 🤯"
                subtitle="Please try again later or contact us at Messenger for help."
              >
                <Button
                  as="a"
                  href="https://go.certifiedcode.global/get/a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c"
                  target="_blank"
                >
                  Reinstall App
                </Button>
              </EmptyState>
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  if (!dashboard) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page>
          <Page.Content>
            <Box
              padding="10px"
              align="center"
              verticalAlign="middle"
              height={"90vh"}
              direction="vertical"
            >
              <Loader text="Loading..." />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const tableComp = () => {
    const records = (brokenLinkScannerResult.data || []).filter(
      (v: any) => v.status < 200 || v.status >= 300
    );

    const columns = [
      { title: "Link", render: (row: any) => row.link },
      { title: "Status", render: (row: any) => row.status },
    ];

    return (
      // @ts-ignore
      <Table data={records} columns={columns} rowVerticalPadding="medium">
        <Table.Content />
      </Table>
    );
  };

  return (
    <ErrorBoundary>
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Notification
          theme="premium"
          show={isLoaded && !isUpgraded}
          type={"global"}
        >
          <Notification.TextLabel>
            {`Upgrade to unlock "${CONTENT[index]}"`}
            {/* {`Upgrade to unlock unlimited usage and more features.`} */}
          </Notification.TextLabel>
          <Notification.ActionButton
            onClick={() => {
              window.open(dashboard["upgradeUrl"], "_blank");
            }}
          >
            Upgrade
          </Notification.ActionButton>
          <Notification.CloseButton />
        </Notification>
        <Page height="100vh">
          <Page.Header
            title="SEO with AI: Optimize Faster"
            subtitle="Explore the apps available to you below."
            actionsBar={
              <Box direction="horizontal" gap="SP1">
                <Cell span={4}>
                  <Button
                    as="a"
                    href={dashboard["upgradeUrl"]}
                    target="_blank"
                    skin="premium"
                    prefixIcon={<PremiumFilled />}
                  >
                    {dashboard["instance"]["instance"]["isFree"]
                      ? "Unlock All AI Tools"
                      : "Manage Plan"}
                  </Button>
                </Cell>
                <Cell span={1}>
                  {isUpgraded && (
                    <Button
                      prefixIcon={<Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c"
                      target="_blank"
                    >
                      Write a review
                    </Button>
                  )}
                </Cell>
              </Box>
            }
          />
          <Page.Content>
            <Modal isOpen={isBrokenLinkScannerOpen}>
              <AnnouncementModalLayout
                title={
                  brokenLinkScannerResult
                    ? brokenLinkScannerResult.message
                    : "Scanner is running"
                }
                primaryButtonText={brokenLinkScannerResult ? "Done" : "Leave"}
                primaryButtonOnClick={() => {
                  setIsBrokenLinkScannerOpen(false);
                }}
                // linkText="Learn More"
                onCloseButtonClick={() => {
                  setIsBrokenLinkScannerOpen(false);
                }}
              >
                {brokenLinkScannerResult ? (
                  <Box direction="vertical" gap="SP1">
                    {tableComp()}
                  </Box>
                ) : (
                  <>
                    <Text>
                      Discover broken links and pages on your site in seconds.
                      <br />
                      The scanner is running in the background and will notify
                      you when it's done.
                    </Text>
                    <LinearProgressBar value={brokenLinkScannerProgress} />
                  </>
                )}
              </AnnouncementModalLayout>
            </Modal>
            <Modal isOpen={isUpScannerOpen}>
              <AnnouncementModalLayout
                title={
                  upScannerResult
                    ? upScannerResult.message
                    : "Scanner is running"
                }
                primaryButtonText={upScannerResult ? "Done" : "Leave"}
                primaryButtonOnClick={() => {
                  setIsUpScannerOpen(false);
                }}
                // linkText="Learn More"
                onCloseButtonClick={() => {
                  setIsUpScannerOpen(false);
                }}
              >
                {upScannerResult ? (
                  <Box direction="vertical" gap="SP1">
                    {/* {tableComp()} */}
                  </Box>
                ) : (
                  <>
                    <Text>Check if your site is down in seconds.</Text>
                    <LinearProgressBar value={upScannerProgress} />
                  </>
                )}
              </AnnouncementModalLayout>
            </Modal>
            {isUpgraded && (
              <Layout>
                <Cell span={12}>
                  <Card>
                    <MarketingLayout
                      imagePadding={false}
                      size="medium"
                      title="Advanced SEO reports & tools is now a part of SEO with AI"
                      description="Professional SEO reports and web tools software designed to help improve the content, quality, and performance of websites."
                      actions={
                        <Box gap="SP1">
                          <Button
                            size="large"
                            suffixIcon={<AIFilled />}
                            as="a"
                            href={`https://manage.seo.certifiedcode.us/?token=${
                              new URLSearchParams(window.location.search).get(
                                "instance"
                              ) || ""
                            }`}
                            // target="_blank"
                            skin="ai"
                          >
                            Join Beta
                          </Button>
                        </Box>
                      }
                      badge={<Badge skin="urgent">Early Beta</Badge>}
                      // imageBackgroundColor="B40"

                      image={<Image src={Pro} width={600} transparent />}
                    />
                  </Card>
                </Cell>
              </Layout>
            )}
            <Layout>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    badge={<Badge skin="warning">New</Badge>}
                    size="tiny"
                    title="UpAI: Better Domain Rating"
                    description="Elevate your site's Domain Rating with UpAI. Get to know how your competitors are preforming and how you can outperform them."
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          as="a"
                          href="https://go.certifiedcode.global/get/2f7d7db1-4a3c-40c3-9863-c4cba88ce1a7"
                          target="_blank"
                        >
                          Add to Site
                        </Button>
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          skin="inverted"
                          as="a"
                          href="https://www.wix.com/app-market/2f7d7db1-4a3c-40c3-9863-c4cba88ce1a7"
                          target="_blank"
                        >
                          Learn more
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
              <Cell>
                <PageSection title="Apps comes with SEO with AI" showDivider />
              </Cell>
              <Cell span={6}>
                <Card>
                  <MarketingLayout
                    title="Broken Link Scanner" //<Box direction="horizontal" gap="SP1"><Heading size="medium" as="h2">Broken Link Scanner</Heading><Badge size="tiny" skin="success">FREE</Badge></Box>}
                    description="Scan your site for broken links and pages in seconds."
                    actions={
                      <Box gap="6px">
                        <Button
                          size="small"
                          onClick={() => {
                            setIsBrokenLinkScannerOpen(true);
                            getBrokenLinks();
                          }}
                        >
                          Scan now
                          <Box marginLeft={"SP1"}>
                            <Badge size="tiny" skin="neutralSuccess">
                              FREE
                            </Badge>
                          </Box>
                        </Button>
                        <Button
                          size="small"
                          disabled={isSaving}
                          skin={!isUpgraded ? "premium" : "inverted"}
                          suffixIcon={
                            !isUpgraded ? <PremiumFilled /> : undefined
                          }
                          onClick={() => {
                            if (isUpgraded) {
                              createBrokenLinksSubscription();
                            } else {
                              window.open(dashboard["upgradeUrl"], "_blank");
                            }
                          }}
                        >
                          {brokenLinkScannerSubscribed
                            ? "Unsubscribe"
                            : "Get notified when found"}
                        </Button>
                      </Box>
                    }
                    size="tiny"
                    image={<></>}
                  />
                  {/* <Divider />
                <Box align="left" padding="24px 30px">
                  <Text size="small" secondary>
                    {`Current usage on your site: ${dashboard['instance']['instance']['isFree'] ? `${(dashboard as any)['usage'].find((v: { type: string; }) => v.type === 'altText')?.count || 0}/15, reset in 24 hours.` : "Unlimited"}`}
                  </Text>
                </Box> */}
                </Card>
              </Cell>
              <Cell span={6}>
                <Card>
                  <MarketingLayout
                    title="Monitor Site Uptime" //<Box direction="horizontal" gap="SP1"><Heading size="medium" as="h2">Broken Link Scanner</Heading><Badge size="tiny" skin="success">FREE</Badge></Box>}
                    description="Get notified when your site is down."
                    actions={
                      <Box gap="6px">
                        <Button
                          size="small"
                          onClick={() => {
                            setIsUpScannerOpen(true);
                            getUp();
                          }}
                        >
                          Check now
                          <Box marginLeft={"SP1"}>
                            <Badge size="tiny" skin="neutralSuccess">
                              FREE
                            </Badge>
                          </Box>
                        </Button>
                        <Button
                          size="small"
                          disabled={isSaving}
                          skin={!isUpgraded ? "premium" : "inverted"}
                          suffixIcon={
                            !isUpgraded ? <PremiumFilled /> : undefined
                          }
                          onClick={() => {
                            if (isUpgraded) {
                              createUpSubscription();
                            } else {
                              window.open(dashboard["upgradeUrl"], "_blank");
                            }
                          }}
                        >
                          {upScannerSubscribed
                            ? "Unsubscribe"
                            : "Get notified when down"}
                        </Button>
                      </Box>
                    }
                    size="tiny"
                    image={<></>}
                  />
                  {/* <Divider />
                <Box align="left" padding="24px 30px">
                  <Text size="small" secondary>
                    {`Current usage on your site: ${dashboard['instance']['instance']['isFree'] ? `${(dashboard as any)['usage'].find((v: { type: string; }) => v.type === 'altText')?.count || 0}/15, reset in 24 hours.` : "Unlimited"}`}
                  </Text>
                </Box> */}
                </Card>
              </Cell>
              <Cell span={6}>
                <Card>
                  <MarketingLayout
                    title="AI Alt Text"
                    description="Generate SEO optimized alt text from images in seconds straight from the Wix and Editor X Editor."
                    actions={
                      <Box gap="6px">
                        <Button
                          as="a"
                          href={
                            "https://chrome.google.com/webstore/detail/cpoemachdhbkgamdikhpmiecophfpemk"
                          }
                          target="_blank"
                          size="small"
                        >
                          Install Now
                        </Button>
                        <Button
                          size="small"
                          skin="inverted"
                          onClick={() => {
                            window.Intercom("showArticle", 6893567);
                          }}
                        >
                          Learn More
                        </Button>
                      </Box>
                    }
                    size="tiny"
                    image={
                      <Box width="100%" align="right">
                        <Image
                          width="240px"
                          src={AltTextThumbnail}
                          transparent
                        />
                      </Box>
                    }
                  />
                  <Divider />
                  <Box align="left" padding="24px 30px">
                    <Text size="small" secondary>
                      {`Current usage on your site: ${
                        dashboard["instance"]["instance"]["isFree"]
                          ? `${
                              (dashboard as any)["usage"].find(
                                (v: { type: string }) => v.type === "altText"
                              )?.count || 0
                            }/15, reset in 24 hours.`
                          : "Unlimited"
                      }`}
                    </Text>
                  </Box>
                </Card>
              </Cell>
              <Cell span={6}>
                <Card>
                  <MarketingLayout
                    title="AI Title & Desc Generator"
                    description="Generate SEO optimized titles and descriptions in seconds straight from the Wix and Editor X Editor."
                    actions={
                      <Box gap="6px">
                        <Button
                          as="a"
                          href={
                            "https://chrome.google.com/webstore/detail/ipniljmjkpmkfgcpklpalkiagpmkgbfj"
                          }
                          target="_blank"
                          size="small"
                        >
                          Install Now
                        </Button>
                        <Button
                          size="small"
                          skin="inverted"
                          onClick={() => {
                            window.Intercom("showArticle", 6863400);
                          }}
                        >
                          Learn More
                        </Button>
                      </Box>
                    }
                    size="small"
                    image={
                      <Box width="100%" align="right">
                        <Image
                          width="240px"
                          src={TextAiThumbnail}
                          transparent
                        />
                      </Box>
                    }
                  />
                  <Divider />
                  <Box align="left" padding="24px 30px">
                    <Text size="small" secondary>
                      {`Current usage on your site: ${
                        dashboard["instance"]["instance"]["isFree"]
                          ? `${
                              (dashboard as any)["usage"].find(
                                (v: { type: string }) => v.type === "generator"
                              )?.count || 0
                            }/15, reset in 24 hours.`
                          : "Unlimited"
                      }`}
                    </Text>
                  </Box>
                </Card>
              </Cell>
            </Layout>
            <Layout>
              {/* <Cell>
                <PageSection
                  title="Partner Offers"
                  subtitle="More tools that help boost site rankings"
                  showDivider
                />
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    badge={<Badge skin="success">FREE TRIAL</Badge>}
                    title="Semrush - The most capable SEO tool on the market"
                    description="Do SEO, content marketing, competitor research,
                    PPC and social media marketing from just one platform."
                    actions={
                      <Button
                        size="small"
                        as="a"
                        href="https://semrush.sjv.io/LPmxbL"
                        target="_blank"
                      >
                        Try for free today
                      </Button>
                    }
                    image={<Image transparent height="90px" src={Semrush} />}
                  />
                </Card>
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    badge={<Badge skin="success">FREE TRIAL</Badge>}
                    title="Sitechecker"
                    description="Sitechecker is a real-time website monitoring platform to protect & grow organic search traffic. World-known brands and governments use Sitechecker to maintain their sites’ technical health and track their success in Google Search."
                    actions={
                      <Button
                        size="small"
                        as="a"
                        href="https://sitechecker.pxf.io/0Z7NYN"
                        target="_blank"
                      >
                        Try for free today
                      </Button>
                    }
                    image={
                      <Image transparent height="120px" src={SiteChecker} />
                    }
                  />
                </Card>
              </Cell> */}
              <Cell>
                <PageSection title="Related services" showDivider />
              </Cell>

              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    badge={<Badge skin="success">RECOMMENDED</Badge>}
                    title="Hire an expert to help your site rank higher on Google"
                    description="From $83/mo, get a dedicated SEO expert to help you optimize your site for search engines. Note: Additional charges applies."
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          as="a"
                          href="https://www.certifiedcode.us/pricing/seo?utm_source=seo-with-ai-banner"
                          target="_blank"
                        >
                          Learn more
                        </Button>
                      </Box>
                    }
                    // imageBackgroundColor="B40"
                    image={<></>}
                  />
                </Card>
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title="Boost your ranking on Google with Citations"
                    description="Get your business listed on 100+ directories and boost your ranking on Google."
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          as="a"
                          href="https://go.certifiedcode.global/get/28af51f1-3e60-4805-8b98-a9052ebfa7b0"
                          target="_blank"
                        >
                          Add to Site
                        </Button>
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          skin="inverted"
                          as="a"
                          href="https://www.wix.com/app-market/28af51f1-3e60-4805-8b98-a9052ebfa7b0"
                          target="_blank"
                        >
                          Learn more
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title="Complete SEO Checklist for your site for $29/mo only"
                    description="We understand that optimizing your website for search engines can be a daunting task, but fear not – we're here to do it for you."
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          as="a"
                          href="https://seo-checklist-onboarding.certifiedcode.us/?utm_source=seo-with-ai-banner"
                          target="_blank"
                        >
                          Get Started
                        </Button>
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          skin="inverted"
                          as="a"
                          href="https://www.certifiedcode.us/services/seo-checklist?utm_source=seo-with-ai-banner"
                          target="_blank"
                        >
                          Learn more
                        </Button>
                      </Box>
                    }
                    // imageBackgroundColor="B40"
                    image={<></>}
                  />
                </Card>
              </Cell>
              <Cell span={12}>
                <BC.Recommends appId="a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c" />
              </Cell>
              <Cell span={12}>
                <BC.Offers
                  appId="a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c"
                  siteId={dashboard?.instance?.site?.siteId}
                />
              </Cell>
            </Layout>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    </ErrorBoundary>
  );
}

export default App;
